.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h1 {
  font-weight: normal;
  font-size: 5rem;
  /* font-family: "STIX Two Math"; */
  font-family: "Roboto Slab", "Rockwell", sans-serif;
  /* font-weight: 700; */
}

.App-header {
  /* background-image: linear-gradient(to right bottom, #57a0c2, #4ea9cc, #43b3d5, #33bcde, #17c6e5); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: "Roboto Slab", "Rockwell", sans-serif;
  color: black;
}

.App-link {
  color: blue;
  text-decoration: none;

}

.LinkBanner {
  display: flex;
  justify-content: space-between; /* or 'space-around' */
  gap: 30px; /* Adjust the value as needed */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
